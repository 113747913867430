@import "colors";
@import "mixins";

$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

html {
  font-size: 16px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1, h2, h3, h4, h5 {
  font-weight: $font-bold;
  letter-spacing: 0.3px;
}

h1 {
  font-size: 3.25rem;
  line-height: 4.5rem;
}

h2 {
  font-size: 2.125rem;
  line-height: 3.5rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

h4 {
  font-size: 1.125rem;
  line-height: 2rem;
}

h5 {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font--medium {
  font-weight: $font-medium;
}

.font--bold {
  font-weight: $font-bold;
}

p,
.text {
  font-size: 1rem;
  font-weight: $font-regular;
  line-height: 1.5rem;
}

.text--s {
  font-size: 0.875rem;
  font-weight: $font-medium;
  line-height: 1.5rem;
}

.text--s-bold {
  font-size: 0.875rem;
  font-weight: $font-bold;
  line-height: 1.5rem;
}

.text--bold {
  font-size: 1rem;
  font-weight: $font-bold;
  line-height: 1.5rem;
}

.text--xs {
  font-size: 0.75rem;
  font-weight: $font-medium;
  line-height: 1.25rem;
}

a,
.link {
  font-weight: $font-bold;
  font-size: 1rem;
  color: $blue-500;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $blue-700;
    @include transition(color .3s ease);
  }
}

.link--s {
  @extend .link;
  font-size: 0.75rem;
}

.eyebrow {
  font-weight: $font-medium;
  font-size: 0.875rem;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}

.eyebrow--table {
  font-weight: $font-bold;
  font-size: 13px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: $grey-60;
}

label.required:after {
  content: " *";
  color: $error;
}