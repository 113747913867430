$spacing-xs: 0.25rem; //4px
$spacing-s: 0.5rem; //8px
$spacing-m: 1rem; //16px
$spacing-l: 1.5rem; //24px
$spacing-xl: 2rem; //32px
$spacing-xxl: 2.5rem; //40px


@mixin generateSpacing($prefix, $property) {
  $sizes: [0, .25, .5, 1, 1.5, 2, 2.5];
  $spacing: 1rem;

  @for $i from 1 through length($sizes) {
    $size: nth($sizes, $i);

    .#{$prefix}-#{$i - 1} {
      #{$property}: $spacing * $size;
    }
  }
}

/* you can use all of these .mt, .pr as inline classes or with @extend */
@include generateSpacing(ml, margin-left);
@include generateSpacing(mt, margin-top);
@include generateSpacing(mr, margin-right);
@include generateSpacing(mb, margin-bottom);

@include generateSpacing(pl, padding-left);
@include generateSpacing(pt, padding-top);
@include generateSpacing(pr, padding-right);
@include generateSpacing(pb, padding-bottom);