.flex {
  display: flex;
}

.flex-justify-start {
  display: flex;
  justify-content: flex-start;
}

.flex-justify-end {
  display: flex;
  justify-content: flex-end;
}

.flex-justify-between {
  display: flex;
  justify-content: space-between;
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.block {
  display: block;
}

.w-full {
  width: 100%;
}

.overflow-scroll {
  overflow: scroll;
}

.relative {
  position: relative;
}