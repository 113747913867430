@import "colors";
@import "mixins";
@import "spacing";
@import "z-index";

.shadow-m {
  box-shadow: 0 1px 4px 0 #dad9de;
}

.dark-shadow {
  box-shadow: 0 6px 24px rgba(19, 46, 83, 0.2) !important;
}

.card {
  background-color: $white;
  border-radius: $spacing-s;
  padding: $spacing-xl;
  border: none;
  margin-bottom: $spacing-l;
  @extend .shadow-m;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

