// file to store z-indexes across the platform.
// never EVER use magic number z-indexes, if necessary rearrange the ones provided

$z-index-negative: -1; // to position eg. absolute elements underneath
$z-index-0: 0;
$z-index-local-top: 1; // to bring some non-global elements of the app on top, as long as they need to be below dropdowns and popups
$z-index-side-nav: 2;
$z-index-dropdown-menu: 3;
$z-index-toast: 4;
$z-index-tooltip: 4;
$z-index-full-screen-modal-backdrop: 5;
$z-index-popup: 6; // only to be used on top of the backdrop shadow with z-index defined above


// ** overrides from 3rd party libraries * //
.modal {
  z-index: $z-index-popup !important;
}

.backdrop,
.modal-backdrop {
  z-index: $z-index-full-screen-modal-backdrop !important;
}

.ng-dropdown-panel,
.cdk-overlay-pane,
.dropdown-menu {
  z-index: $z-index-dropdown-menu !important;
}

.accordion-button {
  z-index: $z-index-0 !important;
}