@mixin border($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color;
}

@mixin padding($top, $right, $bottom, $left) {
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
}

@mixin margin($top, $right, $bottom, $left) {
  margin-top: $top;
  margin-right: $right;
  margin-bottom: $bottom;
  margin-left: $left;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* fallback */
  background: rgba($color, $opacity);
}