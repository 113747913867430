@import "colors";
@import "spacing";
@import "typography";

.btn {
  border-style: solid;
  border-radius: $spacing-xs;
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: $font-medium;
  height: $spacing-xxl;
  padding-left: $spacing-l;
  padding-right: $spacing-l;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn--primary {
  @extend .btn;
  background-color: $blue-500 !important;
  color: $white !important;

  &:hover {
    @include transition(background-color .3s ease);
    background-color: $blue-600 !important;
  }
}

.btn--important {
  @extend .btn;
  background-color: $blue-900 !important;
  color: $white !important;

  &:hover {
    @include transition(background-color .3s ease);
    background-color: #0b1c34 !important;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.btn--full {
  width: 100%;
  border: none;
}

.btn--small {

}

.btn--default {
  @extend .btn;
  background-color: $white;
  color: $black;
  border-width: 1px;
  border-color: $grey-40;

  &:hover {
    @include transition(background-color .3s ease);
    background-color: $grey-10;
  }
}

.btn--ghost {
  @extend .btn;
  background-color: $white;
  color: $blue-500;
  border-width: 0px;
  border-color: $grey-40;

  &:hover {
    color: $blue-600;
  }

  &--secondary {
    color: $grey-60;

    &:hover {
      color: $grey-90;
    }
  }
}

.btn--link {
  background-color: transparent;
  border: none;
  color: $blue-500;
  letter-spacing: 0.3px;
  font-size: 14px;
  font-weight: $font-medium;
  height: $spacing-xxl;

  &:hover {
    color: $blue-600;
  }
}

.btn--link--secondary {
  @extend .btn--link;
  color: $grey-40;

  &:hover {
    color: $grey-60;
  }
}
