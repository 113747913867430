@import "colors";
@import "spacing";
@import "typography";

input:focus, textarea:focus, select:focus {
    &:not(.mat-mdc-date-range-input-inner) {
        outline: none;
        -webkit-box-shadow: none;
    }
}

.label {
    @extend .text--s;
    font-weight: $font-bold;
    color: $grey-90;
    letter-spacing: 0.3px;
}

label {
    @extend .label;
}

input:not(.mat-mdc-date-range-input-inner):not(.ng-select-container):not([type='checkbox']),
.form-control {
    height: $spacing-xxl;
    border-radius: $spacing-xs;
    border: 1px solid $grey-30 !important;
    letter-spacing: 0.2px;
    background-color: $grey-10 !important;

    padding: 0 $spacing-m;
    font-size: 0.875rem;
    font-weight: $font-medium;
    color: $black !important;

    &::placeholder {
        color: $grey-60 !important;
    }

    &:disabled {
        border: 1px solid $grey-10;
        color: $grey-60 !important;
    }

    &.ng-touched.ng-invalid {
        border: 1px solid $error !important;
    }

    &:focus {
        background-color: $grey-10;
    }
}

.form-check-input[type='checkbox'] {
    height: 16px;
    width: 16px;
    padding: 0;
}

.form-switch {
    height: 2.5rem;
}

.form-check-input[type='checkbox'][role='switch'] {
    width: 2em;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
}

.form-check-input:checked {
    background-color: $blue-500;
    border-color: $blue-500;
}

input[type='radio'] {
    height: auto;
    margin-right: $spacing-s;
}

.form__error {
    font-size: 0.75rem;
    color: $error;
    font-weight: $font-regular;
    padding-left: 3px;

    a {
        font-size: 0.75rem;
        color: $blue-500;
    }
}

/* reset */
.form-group {
    margin-bottom: 0;
}

::ng-deep {
    .mdc-line-ripple {
        display: none;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        font-size: unset;
        width: 40px;
        height: 40px;
        padding: 0;

        svg {
            vertical-align: middle;
        }
    }
}

// radio input, material override
.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-hover-icon-color: #18A0EB;
    --mdc-radio-selected-icon-color: #18A0EB;
    --mdc-radio-selected-pressed-icon-color: #18A0EB;
    --mat-mdc-radio-ripple-color: #18A0EB;
    --mat-mdc-radio-checked-ripple-color: #18A0EB;
}

.mat-mdc-standard-chip.mat-primary, .mat-mdc-standard-chip.mat-primary {
    &.cdk-focused {
        background: $blue-100 !important;
    }

    .mat-mdc-chip:hover {
        opacity: .04;
    }

    .mat-mdc-chip-focus-overlay {
        opacity: 0 !important;
    }

    border-radius: 7px !important;
}

.mat-mdc-chip-selected, .mat-mdc-chip-highlighted {
    --mdc-chip-elevated-container-color: #D8EFFB !important;
    --mdc-chip-elevated-disabled-container-color: #D8EFFB !important;
}

.mdc-evolution-chip__graphic {
    display: none !important;
}

.mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic .mdc-evolution-chip__action--primary {
    padding: 7px 12px !important;
    font-size: 15px !important;
}

.mat-mdc-standard-chip:not(.mat-mdc-chip-selected) {
    background: $blue-100;
}

::ng-deep {
    .mat-mdc-radio-button .mdc-radio {
        padding: 0;
    }

    .mdc-form-field {
        -webkit-user-select: none;
        user-select: none;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        vertical-align: middle;
        width: 100%;

        label {
            padding-left: 8px;
            margin-right: $spacing-s;
            color: $black;
            font-weight: $font-medium;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {

        .mdc-evolution-chip__text-label {
            color: $blue-500;
        }
    }
}

