// Generic colors:
$red: #e60000; /* old, remove after redesign */

// Grayscale:
$gray-50: #ccc; /* old, remove after redesign */

// Brand colors:
$blue-80: #023D5C; /* old, remove after redesign */
$cyan-blue-light: #E9F0F6;

// *
// NEW PLATFORM COLORS
// base color is defined by -500, everything below 50 is lighter and above is darker.
// *


/* primary color */
$blue-50: #F3FAFE;
$blue-100: #D8EFFB;
$blue-200: #B9E2F9;
$blue-300: #8BCFF5;
$blue-400: #46B3EF;
$blue-500: #18A0EB;
$blue-600: #1680C1;
$blue-650: #0F63D1;
$blue-700: #156197;
$blue-800: #144571;
$blue-900: #132E53;

/* grayscale */
$black: #171C1E;
$grey-90: #5F6273;
$grey-80: #737785;
$grey-60: #A7ABB9;
$grey-40: #D7DBE6;
$grey-30: #F1F0F3;
$grey-20: #EDF0F9;
$grey-10: #F8F9FC;
$grey-shadow-10: #DAD9DE;
$off-white: #FCFCFF;
$white: #FFFFFF;

/* semantic */
$success: #00D69E;
$warning: #FFC436;
$error: #ff7a86;

/* accent, to be updated with more, usage in charts graphs etc: */
$green-50: #00D69E;
$green-30: #44EFC2;
$green-70: #158668;

$violet-50: #7100D6;
$violet-30: #B56DF5;
$violet-70: #582586;

$skyblue-50: #18A0EB;
$skyblue-20: #c6ebff;
$skyblue-30: #77CBFB;
$skyblue-70: #2374A1;
$skyblue-80: #DFEBF5CC;

$old-background: #f8f9fa;

$black: #000000;
$orange-600: #ffae03;