@import "colors";
@import "mixins";
@import "spacing";
@import "typography";

.alert--success {
  @include background-opacity($success, 0.2);
  color: $success;
  padding: $spacing-s $spacing-m;
  border: 1px solid $success;
  border-radius: $spacing-xs;
  letter-spacing: 0.2px;
  font-weight: $font-medium;
}